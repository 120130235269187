<template>
  <div class="auditlog-wrapper">
    <Breadcrumbs />
    <v-row
      class="content-wrapper pa-0"
      style="flex-wrap: nowrap;overflow:auto;"
    >
      <v-col cols="9">
        <v-card outlined class="rounded-sm" style="height:738px">
          <v-card-text>
            <v-app-bar
              elevation="0"
              dense
              flat
              style="background: none; margin-left: -12px"
            >
              <v-text-field
                v-model="mobile"
                :append-icon="mobile ? 'mdi-close-circle' : null"
                label="手机号"
                @click:append="clearMobile"
                placeholder="请输入手机号"
                hide-details="auto"
                class="mr-4 loginname"
                style="width: 140px; flex: none; background: none"
              ></v-text-field>
              <v-menu
                class="datepicker-menu"
                ref="menu"
                v-model="show_start_datepicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-4 mt-5 loginname"
                    style="width: 130px; flex: none; background: none"
                    v-model="start_time"
                    :append-icon="start_time ? 'mdi-close-circle' : ''"
                    @click:append="clearStartTime"
                    placeholder="开始时间"
                    label="开始时间"
                    readonly
                    rows="1"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_time"
                  :allowed-dates="allowedStartDate"
                  no-title
                  scrollable
                  :show-current="false"
                  @input="show_start_datepicker = false"
                  :day-format="date => date.split('-')[2]"
                  locale="zh-cn"
                >
                </v-date-picker>
              </v-menu>
              <v-menu
                class="datepicker-menu"
                ref="menu"
                v-model="show_end_datepicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-4 mt-5 loginname"
                    style="width: 130px; flex: none; background: none"
                    v-model="end_time"
                    :append-icon="end_time ? 'mdi-close-circle' : ''"
                    @click:append="clearEndTime"
                    placeholder="结束时间"
                    label="结束时间"
                    readonly
                    rows="1"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_time"
                  :allowed-dates="allowedEndDate"
                  no-title
                  scrollable
                  :show-current="false"
                  @input="show_end_datepicker = false"
                  :day-format="date => date.split('-')[2]"
                  locale="zh-cn"
                >
                </v-date-picker>
              </v-menu>
              <v-btn
                depressed
                color="primary"
                style="padding: 0 24px; height: 34px !important"
                @click.stop="
                  searchLogs(
                    mobile,
                    start_time,
                    end_time,
                    1,
                    10
                  )
                "
              >
                {{ $t("account.Search") }}
              </v-btn>
            </v-app-bar>
            <v-simple-table class="table-wrapper ml-0">
              <thead>
                <tr>
                  <th class="text-left pl-4">
                    {{ $t("sms_log.login.id") }}
                  </th>
                  <th class="text-left pl-4">
                    {{ $t("sms_log.login.mobile") }}
                  </th>
                  <th class="text-left" style="width:144px">
                    {{ $t("audit_log.login.time") }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="logs.length > 0">
                <tr v-for="item in logs" :key="item.id" class="text-left pl-4">
                  <td>{{ item.id }}</td>
                  <td>{{ item.mobile }}</td>
                  <td>
                    {{ item.created.replace(/T/, " ") }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="text-center">
                  <td
                    colspan="5"
                    style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
                  >
                    暂无数据
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
           <div style="display:flex;justify-content: flex-end; align-items: center;"> <v-pagination
              v-if="Math.ceil(totalPage / limit) > 1"
              v-model="curPage"
              :length="Math.ceil(totalPage / limit)"
              total-visible="7"
              @input="
                onPageChange(
                  mobile,
                  start_time,
                  end_time,
                  curPage,
                  limit
                )
              "
            ></v-pagination>
            <span v-if="totalPage > 0">{{ `共${totalPage}条` }}</span></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import { iso8601 } from "@/util/misc";
import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmBox from "@/components/ConfirmBox";

export default {
  name: "SmsLog",
  data() {
    return {
      curPage: 1,
      limit: 10,
      totalPage: 0,
      logs: [],
      show_start_datepicker: false,
      show_end_datepicker: false,
      ENABLE_STATUS: ["全部", "成功", "失败"],
      enableIdx: null,
      enableStatus: "",
      start_time: null,
      end_time: null,
      selectedItem: "",
      loginName: null,
      clientIp: null,
      searchName: "",
      accountId: null,
      sourceId: null,
      mobile: null,
    };
  },
  async created() {
      await this.refreshList();
  },
  methods: {
    refreshList(curPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/sms/logs?offset=${(curPage - 1) *
            limit}&limit=${limit}&need_count=true`
        )
        .delegateTo(api_request)
        .then(res => {
          this.logs = res.logs;
          this.totalPage = res.total;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    onPageChange(
      mobile,
      start_time,
      end_time,
      curPage,
      limit
    ) {
      this.searchLogs(
        mobile,
        start_time,
        end_time,
        curPage,
        limit
      );
    },
   
    // 清空开始时间
    clearStartTime() {
      this.start_time = null;
      this.curPage = 1;
      if (
        this.mobile != null ||
        this.end_time != null
      ) {
        this.searchLogs(
          this.mobile,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
    // 清空结束时间
    clearEndTime() {
      this.end_time = null;
      this.curPage = 1;
      if (
        this.mobile != null ||
        this.start_time != null
      ) {
        this.searchLogs(
          this.mobile,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
// 清空客户端IP
clearMobile() {
      this.mobile = null;
      this.curPage = 1;
      if (
        this.mobile != null ||
        this.start_time != null ||
        this.end_time != null
      ) {
        this.searchLogs(
          this.mobile,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
 
    searchLogs(
      mobile,
      start_time,
      end_time,
      curPage = 1,
      limit = 10
    ) {
      // 调取右侧table列表搜索api
      return this.$http
        .get(
          `api/sms/logs?mobile=${
            mobile ? mobile : ""
          }&start_time=${
            start_time ? `${start_time} 00:00:00` : ""
          }&end_time=${
            end_time !== 1 && end_time != null ? `${end_time} 00:00:00` : ""
          }&offset=${(curPage - 1) *
            limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.logs = res.logs;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    allowedStartDate(val) {
      if (this.end_time) {
        let cur = new Date(val).getTime();
        let end = new Date(this.end_time).getTime();

        return cur < end;
      }
      return true;
    },
    allowedEndDate(val) {
      if (this.start_time) {
        let cur = new Date(val).getTime();
        let end = new Date(this.start_time).getTime();
        return cur > end;
      }
      return true;
    }
  },
  watch: {
    start_time() {
      if (this.start_time) {
        this.searchLogs(
          this.mobile,
          this.start_time,
          this.end_time,
          (this.curPage = 1)
        );
      }
    },
    end_time() {
      if (this.end_time) {
        this.searchLogs(
          this.mobile,
          this.start_time,
          this.end_time,
          (this.curPage = 1)
        );
      }
    }
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    },
    today() {
      return iso8601(new Date());
    }
  },
  components: {
    Breadcrumbs,
    ConfirmBox
  }
};
</script>

<style lang="less" scoped>
.auditlog-wrapper {
  width: 100%;
  .table-wrapper {
    min-height: 600px;
  }
  /deep/.v-pagination {
    justify-content: flex-end;
    width: 96%;
  }
  .v-list-item:hover {
    border-right: 2px solid #00b0ff;
  }
  .v-list-item--active {
    border-right: 2px solid #0091ea;
  }
}

/deep/.loginname .v-icon.v-icon {
  font-size: 16px;
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
